<template>
    <div>
      <v-overlay :value="overlay" absolute style="z-index:999999999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
  
      <v-toolbar flat>
        <v-icon left>mdi-pencil</v-icon>
        <v-toolbar-title>MENSAJES RÁPIDOS WHATSAPPI</v-toolbar-title>
        <v-divider class="mx-4" vertical></v-divider>
        <v-btn 
          @click="nuevaPlantilla(null)" 
          color="blue" 
          small 
          dark
        >
          <v-icon>mdi-plus</v-icon> Crear
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items-per-page="itemsPerPage"
        :items="plantillas"
        class="elevation-1"
        small
        item-key="message_template_id"
        hide-default-footer
      >
        <template v-slot:item.opciones="{ item }">
          
          <v-btn
            small
            text
            @click="borrarPlantilla(item.message_template_id)"
            color="blue"
            dark
            ><v-icon small color="red">mdi-delete</v-icon></v-btn
          >
          <v-btn icon small color="green" @click="editarPlantilla(item)">
            <v-icon small color green>mdi-pencil</v-icon>
          </v-btn>
        </template>
  
        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="currentPage"
            :length="pageCount"
            @input="handlePageChange"
            total-visible="10"
          ></v-pagination>
        </template>
      </v-data-table>
  
      <!-- DIALOGO PLANTILLA -->
      <v-dialog v-model="dialogoPlantilla" persistent width="700">
        <v-form ref="formPlantilla">
          <v-card>
            <v-toolbar dark color="blue" height="40" flat>
              <v-icon left>mdi-account-box-outline</v-icon>
              Plantilla
              <v-spacer></v-spacer>
              <v-btn small text @click="dialogoPlantilla = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-row class="mt-1 mx-2 pt-0 mt-0">
              <v-col md="12" class="py-0 my-0">
                <v-text-field
                  :rules="requiredRule"
                  v-model="datosMensaje.nombre"
                  label="Nombre"
                /> 
              </v-col>
            </v-row>
            <v-row class="mt-1 mx-2 pt-0 mt-0">
              <v-col md="12" class="py-0 my-0">
                <v-textarea
                  :rules="requiredRule"
                  v-model="datosMensaje.texto"
                  label="Texto"
                ></v-textarea>
              </v-col>
            </v-row>
  
            <v-row class="mt-1 mx-2 pt-0 mt-0">
              <v-col md="6" class="py-0 my-0">
                <v-text-field
                  type="date"
                  readonly
                  v-model="datosMensaje.desde"
                  label="Desde"
                ></v-text-field>
              </v-col>
  
              <v-col md="6" class="py-0 my-0">
                <v-text-field
                  type="date"
                  :rules="requiredRule"
                  v-model="datosMensaje.hasta"
                  label="Hasta"
                ></v-text-field>
              </v-col>
            </v-row>
  
            <v-toolbar class="pt-2 pb-2" flat>
              <v-btn class="mr-4" color="primary" small @click="guardarPlantilla">
                <v-icon>mdi-content-save</v-icon>Guardar
              </v-btn>
  
              <v-btn class="mx-4" text small @click="dialogoPlantilla = false">
                <v-icon>mdi-content-cancel</v-icon>Cancelar
              </v-btn>
            </v-toolbar>
          </v-card>
        </v-form>
      </v-dialog>  
    </div>
  </template>
  
  <script>
  import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
  import Vue from "vue";
  import axios from "axios";
  
  export default {
    name: "TextosRapidosWa",
    data: () => ({
      requiredRule: [(v) => !!v || "El campo es requerido"],
      options: {},
      setfilters: [],
      selected: [],
      users: [],
      totalItems: 1,
      currentPage: 1,
      itemsPerPage: 10,
      pageCount: 0,
      required: [(v) => !!v || "El campo es requerido"],
      headers: [
        { text: "Opciones", align: "start", value: "opciones", width: 120 },
        { text: "ID", align: "start", value: "message_template_id" },
        { text: "Nombre", align: "start", value: "nombre" },
        { text: "Texto", align: "start", value: "texto" },
        { text: "Desde", align: "start", value: "desde" },
      ],
  
      dialogoPlantilla: false,
      datosMensaje: {
        message_template_id: "",
        nombre: "",
        texto: "",
        desde: "",
        hasta: "",
        modulo:""
      },
      crearPlantilla: false,
      plantillas: [],
      dialogoImagen: false,
      dialogWidth: 400,
      overlay:false
  
    }),
    computed: {    
      ...mapState("master", ["loadingBtn", "companies","token", "tenantId", "user", "url" ]),
      ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),    
    },
    methods: {
      ...mapMutations("master", [
        "setUrl",
        "setLoadingTable",
        "setTitleToolbar",
        "setTenantId",
      ]),
  
      ...mapActions("master", ["requestApi", "alertNotification"]),
      ...mapActions("access", []),
  
      //cargar datos de la plantilla
      cargarDatos() {

        this.setLoadingTable(true);
  
        this.setUrl("message-template");
  
        this.requestApi({
          method: "GET",
          data: {
            page: this.currentPage,
            page_size: this.itemsPerPage,
            paginator: true,
            typeList: 'getTemplate',
            typeTemplate: 'TEXTO_RAPIDO_WA'
          },
        })
          .then((res) => {
            //console.log(res.data);
            this.plantillas = res.data._embedded.message_template;
            this.pageCount = res.data.page_count;
            this.currentPage = res.data.page == 0 ? 1 : res.data.page;
            this.totalItems = res.data.total_items;
          })
          .then(() => {
            this.setLoadingTable(false);
          });
      },
  
      //nueva plantilla
      nuevaPlantilla(plantillaId) {
  
        this.dialogoPlantilla = true;
        
        this.datosMensaje = {
          message_template_id: "",
          tipo_mensaje_id: "TEXT",
          nombre: "",
          texto: "",
          desde: new Date().toISOString().substring(0,10),
          hasta: "",
          modulo: "",
        }

        this.crearPlantilla = true
       
      },

      editarPlantilla(item) {

          this.dialogoPlantilla = true
          this.crearPlantilla = false
          
          this.datosMensaje = {
            message_template_id: item.message_template_id,
            nombre: item.nombre,
            texto: item.texto,
            modulo: item.modulo,
            tipo_mensaje_id: item.tipo_mensaje_id,
            url_archivo: item.data_resource_name,
            desde: item.desde.substring(0,10),
            hasta: item.hasta != null ? item.hasta.substring(0,10) : '',
            content_id: item.content_id
          }

      },
  
      guardarPlantilla() {
  
        if (!this.$refs.formPlantilla.validate()) 
          return false;
        
        this.overlay = true
        let formData = new FormData();
        formData.append("accion", this.crearPlantilla == true ? "crearTemplate" : "modificarTemplate");
        formData.append("plantilla", JSON.stringify(this.datosMensaje));
        formData.append("tenantId", this.tenantId);
        formData.append("tipo_plantilla", "TEXTO_RAPIDO_WA");
  
        this.setUrl("message-template");

        axios.post(this.url, formData, {
              headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + this.token,
              },
            }).then((res) => {          
                
                this.cargarDatos();
                this.dialogoPlantilla = false;
                this.alertNotification({
                    param: {
                        html: res.data.detail.msg,
                    },
                });
                this.overlay = false;
            }).catch((err) => {              
                this.overlay = false;
            }).finally((err) => {              
                this.overlay = false;
            });      
      },
  
      borrarPlantilla(plantillaId) {

        Vue.swal({
          html: "Está seguro de eliminar este mensaje rápido?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cerrar",
          ...this.paramAlertQuestion,
        }).then((result) => {

          if (result.isConfirmed) {

            this.setLoadingTable(true);
            this.setUrl("message-template");
            this.requestApi({
              method: "DELETE",
              data: {
                accion: "borrarPlantilla",
                message_template_id: plantillaId,
              },
            })
              .then((res) => {

                this.cargarDatos();
                this.dialogoPlantilla = false

              })
              .then(() => {

                this.setLoadingTable(false)

              })

          }

        })

      },
  
      handlePageChange() {
        this.cargarDatos();
      },

    },
    mounted() {
      this.cargarDatos();
      this.setTitleToolbar("MENSAJES RÁPIDOS");
    },
  };
  </script>
  